import { z } from "zod";
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

export const RegisterTwoStepSchema = z.object({
 email: z
        .string()
        .email("E-mail inválido!")
        .min(1, { message: "Email é obrigatório!" }),
        password: z
        .string()
        .min(1, { message: "Senha é obrigatório!" })
        .regex(passwordRegex, { message: "A senha deve conter pelo menos 8 caracteres, incluindo pelo menos 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial." }),
     repeatPassword: z.string().min(1, { message: "Repetir senha é obrigatório!" }),
    politica: z
        .boolean()
        .refine((value) => value === true, "Política é obrigatório!"),

}).refine(data => data.password === data.repeatPassword, {
    message: "As senhas devem corresponder",
    path: ["repeatPassword"], // path of error
});
