import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "components/Button";
import { Input } from "components/Input";
import { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { RegisterSchema } from "schemas";
import { ThemeContext } from "styled-components";
import { DataProps } from "types";
import * as S from "../style";
import { api } from "api/api";
import { useStoreRegister } from "contexts/Register.context";

export default function LoginFirstStep() {
    const theme = useContext(ThemeContext);
    const { setUserId, useId, setName, setLastName,setStep, setDocument, setPhone, setCompanyName } = useStoreRegister();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<DataProps<typeof RegisterSchema>>({
        mode: "onBlur",
        resolver: zodResolver(RegisterSchema),
    });

    useEffect(() => {
       async function GetNextId(){
            const response = await api("arquiteto/next-id", {
                method: "GET",
            });
            setUserId(response);
        }
        GetNextId();
    }, []);
    const submitForm = async (data: any) => {
        setName(data.name);
        setLastName(data.lastName);
        setDocument(data.document);
        setPhone(data.phone);
        setCompanyName(data.companyName);
        setStep(2);
    };
    return (
 <S.FormInputContainer onSubmit={handleSubmit(submitForm)}>
                    <S.ContentInputRow>
                        <Input.Text
                            {...register("name")}
                            type="text"
                            name="name"
                            placeholder="Nome"
                            autoComplete="name"
                            label="Nome"
                            height={54}
                            helperText={errors?.name?.message}
                            borderRadius={8}
                            padding={20}
                            required
                        />
                        <Input.Text
                            {...register("lastName")}
                            type="text"
                            name="lastName"
                            placeholder="Sobrenome"
                            autoComplete="lastName"
                            label="Sobrenome"
                            helperText={errors?.lastName?.message}
                            height={54}
                            borderRadius={8}
                            padding={20}
                        />
                    </S.ContentInputRow>
                    <S.ContentInputRow>
                        <Input.Text
                            {...register("document")}
                            type={"text"}
                            name="document"
                            placeholder="CPF/CNPJ"
                            label="CPF ou CNPJ"
                            helperText={errors?.document?.message}
                            height={54}
                            borderRadius={8}
                            padding={20}
                            required
                        />
                        <Input.Text
                            {...register("phone")}
                            type={"text"}
                            name="phone"
                            label="Telefone celular"
                            placeholder="Telefone celular"
                            helperText={errors?.phone?.message}
                            height={54}
                            borderRadius={8}
                            padding={20}
                            required
                        />
                    </S.ContentInputRow>
                    <S.ContentInputRow>
                        <Input.Text
                            value={useId}
                            type={"text"}
                            name="document"
                            placeholder="ID de usuário"
                            label="ID de usuário"
                            height={54}
                            borderRadius={8}
                            padding={20}
                            disabled
                        />
                        <Input.Text
                            {...register("companyName")}
                            type={"text"}
                            name="companyName"
                            placeholder="Nome da empresa"
                            label="Nome da empresa"
                            height={54}
                            borderRadius={8}
                            padding={20}
                        />
                    </S.ContentInputRow>
                    <S.ContentButton>
                        <Button.Master
                            width={300}
                            height={54}
                            size={1.125}
                            type="submit"
                            color={theme.mediumBlue}
                        >
                            Próximo passo
                        </Button.Master>
                    </S.ContentButton>
                </S.FormInputContainer>
    );
}
