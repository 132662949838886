import { useStoreRegister } from "contexts/Register.context";
import { useContext, useState } from "react";

import { ThemeContext } from "styled-components";
import * as S from "../style";

import { Button } from "components/Button";
import Loading from "components/Loading";

export default function LoginForgotPasswordSuccess() {
    const theme = useContext(ThemeContext);
    const [isloading, setIsLoading] = useState<boolean>(false);
    const { setStep} = useStoreRegister();

    return(
        <S.FormInputContainerTwo >

        <S.ContainerTwo>
            <S.FourH3>
            Senha alterada
            </S.FourH3>
            <S.DescriptionForgotPassword>
            Sua senha foi alterada com sucesso! Você pode acessar com sua nova senha.
            </S.DescriptionForgotPassword>

            <S.ContentButton>
                        <Button.Master
                            width={300}
                            height={54}
                            size={1.125}
                            type="button"
                            color={theme.mediumBlue}
                            onClick={()=> setStep(4)}
                        >
                            {isloading ? <Loading size={20} /> : "Ir para login"}
                        </Button.Master>
                    </S.ContentButton>
            </S.ContainerTwo>
            </S.FormInputContainerTwo>
    );
}
