export default () => (
    <svg
        width="120"
        height="120"
        viewBox="0 0 120 120"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            x="0.200195"
            y="0.5"
            width="119"
            height="119"
            rx="8"
            fill="url(#paint0_linear_1152_1714)"
        />
        <path
            d="M55.616 65.384H57.384V61.384H61.616V65.384H63.384V54.616H61.616V59.616H57.384V54.616H55.616V65.384ZM66.154 65.384H71.77C72.362 65.384 72.8687 65.1733 73.29 64.752C73.7127 64.3307 73.924 63.824 73.924 63.232V56.77C73.924 56.1767 73.7127 55.6693 73.29 55.248C72.8687 54.8267 72.362 54.616 71.77 54.616H66.154V65.384ZM67.924 63.616V56.384H71.538C71.6927 56.384 71.834 56.448 71.962 56.576C72.09 56.7053 72.154 56.8467 72.154 57V63C72.154 63.1533 72.09 63.2947 71.962 63.424C71.834 63.552 71.6927 63.616 71.538 63.616H67.924ZM46.076 65.384H47.846V61.23H51.846V59.462H47.846V56.384H52.846V54.616H46.076V65.384ZM43.23 74C42.31 74 41.542 73.692 40.926 73.076C40.3087 72.4587 40 71.69 40 70.77V49.23C40 48.31 40.3087 47.542 40.926 46.926C41.542 46.3087 42.31 46 43.23 46H76.77C77.69 46 78.458 46.3087 79.074 46.926C79.6913 47.542 80 48.31 80 49.23V70.77C80 71.69 79.692 72.458 79.076 73.074C78.4587 73.6913 77.69 74 76.77 74H43.23ZM43.23 72H76.77C77.1287 72 77.4233 71.8847 77.654 71.654C77.8847 71.4233 78 71.1287 78 70.77V49.23C78 48.8713 77.8847 48.5767 77.654 48.346C77.4233 48.1153 77.1287 48 76.77 48H43.23C42.8713 48 42.5767 48.1153 42.346 48.346C42.1153 48.5767 42 48.8713 42 49.23V70.77C42 71.1287 42.1153 71.4233 42.346 71.654C42.5767 71.8847 42.8713 72 43.23 72Z"
            fill="white"
        />
        <defs>
            <linearGradient
                id="paint0_linear_1152_1714"
                x1="60.527"
                y1="2.61422"
                x2="58.854"
                y2="119.488"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#487B9D" />
                <stop offset="0.447917" stopColor="#79AAC1" />
                <stop offset="1" stopColor="#A3D1E0" />
            </linearGradient>
        </defs>
    </svg>
);
