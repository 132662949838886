import { HelperText } from "components";
import React, { useId } from "react";
import { InputType } from "types";
import * as S from "./style";

const Input = React.forwardRef<HTMLInputElement, InputType>(
    (
        {
            type = "text",
            height,
            borderRadius,
            padding,
            name = "",
            label = "",
            helperText = "",
            required = false,
            ...props
        },
        ref
    ) => {
        const inputId = useId();
        const hasError = helperText.length > 0;
        return (
            <S.Container>
                <S.Label padding={padding} htmlFor={inputId}>
                    {label}
                    {required && <strong>*</strong>}
                </S.Label>
                <S.Input
                    ref={ref}
                    type={type}
                    id={inputId}
                    name={name}
                    height={height}
                    padding={padding}
                    borderRadius={borderRadius}
                    hasError={hasError}
                    {...props}
                />

                {hasError && <HelperText helperText={helperText} />}
            </S.Container>
        );
    }
);

export default Input;
