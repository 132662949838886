import {
    // Boleto,

    Card,
    //  PayByBi,
    // Pix,
} from "assets/icons";

export const typesPayment = [
    // {
    //     icon: <PayByBi />,
    //     title: "PayByBio",
    //     type: "paybybio",
    // },
    {
        icon: <Card />,
        title: "Cartão de crédito",
        type: "card",
    },
    // {
    //     icon: <Pix />,
    //     title: "PIX",
    //     type: "pix",
    // },
    // {
    //     icon: <Boleto />,
    //     title: "Boleto bancário",
    //     type: "boleto",
    // },
];
