import { useForm } from "react-hook-form";
import * as S from "../style";
import { RegisterTwoStepSchema } from "schemas";
import { DataProps } from "types";
import { zodResolver } from "@hookform/resolvers/zod";
import { useStoreRegister } from "contexts/Register.context";
import { ThemeContext } from "styled-components";
import { Input } from "components/Input";
import { Button } from "components/Button";
import { useContext } from "react";
import HelperText from "components/HelperText";
import { useNavigate } from "react-router-dom";
import { useUsers } from "hooks";
import { Loading } from "components";
export default function LoginStepTwo(){
    const router = useNavigate();
    const { postUser, isloading } = useUsers();
    const theme = useContext(ThemeContext);
    const {name,lastName,companyName,document,phone,useId,setEmail, setSenha,setStep, setPolitica} = useStoreRegister();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<DataProps<typeof RegisterTwoStepSchema>>({
        mode: "onBlur",
        resolver: zodResolver(RegisterTwoStepSchema),
    });

    const submitForm = async (data: any) => {
       setEmail(data.email);
       setSenha(data.password);
       setPolitica(data.politica);
       const dataUser = {
        name: name + " " + lastName,
        document,
        phone,
        useId: Number(useId),
        companyName,
              email: data.email,
              password: data.password,
       };
       const response = await postUser(dataUser);
       if (response.code) return;
       setStep(3);
    };

return(
    <S.FormInputContainerTwo onSubmit={handleSubmit(submitForm)}>
        <S.ContainerTwo>
        <S.ContentInputRow>
            <Input.Text
                {...register("email")}
                type="text"
                name="email"
                label="E-mail"
                placeholder="E-mail"
                autoComplete="email"
                height={54}
                borderRadius={8}
                padding={20}
                helperText={errors?.email?.message}
                required
            />
             </S.ContentInputRow>

             <S.ContentInputRow>
            <Input.Password
                {...register("password")}
                name="password"
                placeholder="Senha"
                autoComplete="current-password"
                height={54}
                label="Senha"
                required
                borderRadius={8}
                padding={20}
                helperText={errors?.password?.message}
            />
            </S.ContentInputRow>
            <S.ContentInputRow>
            <Input.Password
                {...register("repeatPassword")}
                name="repeatPassword"
                placeholder="Insira sua senha"
                label="Repetir senha"
                required
                height={54}
                borderRadius={8}
                padding={20}
                helperText={errors?.repeatPassword?.message}
            />
            </S.ContentInputRow>
            <S.ContentButton>
                        <Button.Master
                            width={300}
                            height={54}
                            size={1.125}
                            type="submit"
                            color={theme.mediumBlue}
                        >
                            {isloading ? <Loading size={20} /> : "Cadastrar"}
                        </Button.Master>
                    </S.ContentButton>
                    <S.RegisterPolitica>
                        <S.Politica>
                            <input {...register("politica")} type="checkbox" />
                            <S.TitlePolitica>
                                Ao criar sua conta, você aceita nossos{" "}
                                <strong onClick={() => router("/politica")}>
                                    Termos e condições
                                </strong>{" "}
                                e nossa{" "}
                                <strong onClick={() => router("/politica")}>
                                    Política de privacidade
                                </strong>
                                .
                            </S.TitlePolitica>
                        </S.Politica>
                        {errors?.politica?.message && (
                            <HelperText
                                helperText={errors?.politica?.message}
                            />
                        )}
                    </S.RegisterPolitica>
                    </S.ContainerTwo>
    </S.FormInputContainerTwo>
);
}
