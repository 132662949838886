import { ButtonType } from "types";
import * as S from "./style";

export default function MasterButton({
    color,
    width,
    height,
    size,
    children = "",
    ...props
}: ButtonType) {
    return (
        <S.Button
            color={color}
            size={size}
            height={height}
            width={width}
            {...props}
        >
            {children}
        </S.Button>
    );
}
