import { Footer, Header, Modal } from "components";
import { Outlet } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import theme from "../styles/themes/colors";
import ModalContent from "./ModalContent";
import { RetangleBlue } from "./RetangleBlue";
import * as S from "./style";

export default function Layout() {
    return (
        <ThemeProvider theme={theme}>
            <S.Wrapper>
                <RetangleBlue.Left />
                <RetangleBlue.Rigth />
                <Modal />

                <Header />
                <Outlet />
                <Footer />
                <ModalContent  />
            </S.Wrapper>
        </ThemeProvider>
    );
}
