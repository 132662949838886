import InputText from "./Input";
import InputFile from "./InputFile";
import InputSearch from "./InputSearch";
import Select from "./Select";
import InputTextarea from "./Textarea";
import InputCheckbox from "./Checkbox";
import InputFileContrat from "./InputFileContrat";
import InputPassword from "./InputPassword";


export const Input = {
    Text: InputText,
    Search: InputSearch,
    File: InputFile,
    FileContrat: InputFileContrat,
    Select: Select,
    Textarea: InputTextarea,
    Checkbox: InputCheckbox,
    Password: InputPassword,
};
