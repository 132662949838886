import { z } from "zod";

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;


export const ForgotPasswordChangeSchema = z.object({
    password: z
    .string()
    .min(1, { message: "Senha é obrigatório!" })
    .regex(passwordRegex, { message: "A senha deve conter pelo menos 8 caracteres, incluindo pelo menos 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial." }),
 repeatPassword: z.string().min(1, { message: "Repetir senha é obrigatório!" }),

    });
