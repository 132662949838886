export default () => (
    <svg
        width="53"
        height="630"
        viewBox="0 0 53 630"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M26 4L26 626"
            stroke="url(#paint0_linear_3180_4090)"
            stroke-width="8"
            stroke-linecap="square"
        />
        <circle
            cx="26.5"
            cy="314.5"
            r="25.5"
            fill="url(#paint1_linear_3180_4090)"
            stroke="#487B9D"
        />
        <path
            d="M32 308.341C32 307.27 33.2175 306.633 34.1241 307.229L43.3963 313.343C43.5815 313.465 43.7333 313.63 43.8386 313.823C43.9438 314.016 43.9992 314.232 44 314.451C44.0007 314.67 43.9468 314.885 43.8429 315.079C43.739 315.273 43.5883 315.439 43.404 315.562L34.1307 321.768C33.9257 321.905 33.6867 321.984 33.4392 321.998C33.1917 322.011 32.9452 321.958 32.7261 321.845C32.507 321.731 32.3236 321.561 32.1957 321.352C32.0678 321.144 32.0001 320.905 32 320.661V308.341Z"
            fill="#4D4D4D"
        />
        <path
            d="M22 320.659C22 321.73 20.7825 322.367 19.8759 321.771L10.6037 315.657C10.4185 315.535 10.2667 315.37 10.1614 315.177C10.0562 314.984 10.0008 314.768 10 314.549C9.99925 314.33 10.0532 314.115 10.1571 313.921C10.261 313.727 10.4117 313.561 10.596 313.438L19.8693 307.232C20.0743 307.095 20.3133 307.016 20.5608 307.002C20.8083 306.989 21.0548 307.042 21.2739 307.155C21.493 307.269 21.6764 307.439 21.8043 307.648C21.9322 307.856 21.9999 308.095 22 308.339L22 320.659Z"
            fill="#4D4D4D"
        />
        <defs>
            <linearGradient
                id="paint0_linear_3180_4090"
                x1="25.9822"
                y1="319.322"
                x2="24.9999"
                y2="319.322"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#487B9D" />
                <stop offset="0.447917" stopColor="#79AAC1" />
                <stop offset="1" stopColor="#A3D1E0" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_3180_4090"
                x1="26.8544"
                y1="289.906"
                x2="26.1374"
                y2="339.995"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#487B9D" />
                <stop offset="0.447917" stopColor="#79AAC1" />
                <stop offset="1" stopColor="#A3D1E0" />
            </linearGradient>
        </defs>
    </svg>
);
