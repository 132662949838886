import { create } from "zustand";
type Register = {
    useId: string;
    setUserId: (useId: string) => void;
    name: string;
    setName: (name: string) => void;
    lastName: string;
    setLastName: (lastName: string) => void;
    document: string;
    setDocument: (document: string) => void;
    phone: string;
    setPhone: (phone: string) => void;
    companyName: string;
    setCompanyName: (companyName: string) => void;
    email: string;
    setEmail: (email: string) => void;
    senha: string;
    setSenha: (senha: string) => void;
    politica: boolean;
    setPolitica: (politica: boolean) => void;
    step: number;
    setStep: (step: number) => void;
    validateEmail: boolean;
    setValidateEmail: (validateEmail: boolean) => void;
    openModal: boolean;
    setOpenModal: (openModal: boolean) => void;
    handleCloseModal: () => void;
}

export const useStoreRegister = create<Register>((set) => ({
    useId: "000000",
    setUserId: (useId) => set({ useId }),
    name: "",
    setName: (name) => set({ name }),
    lastName: "",
    setLastName: (lastName) => set({ lastName }),
    document: "",
    setDocument: (document) => set({ document }),
    phone: "",
    setPhone: (phone) => set({ phone }),
    companyName: "",
    setCompanyName: (companyName) => set({ companyName }),
    email: "",
    setEmail: (email) => set({ email }),
    senha: "",
    setSenha: (senha) => set({ senha }),
    politica: false,
    setPolitica: (politica) => set({ politica }),
    step: 1,
    setStep: (step) => set({ step }),
    validateEmail: false,
    setValidateEmail: (validateEmail) => set({ validateEmail }),
    openModal: false,
    setOpenModal: (openModal) => set({ openModal }),
    handleCloseModal: () => set({
        openModal: false,
        useId: "000000",
        name: "",
        lastName: "",
        document: "",
        phone: "",
        companyName: "",
        email: "",
        senha: "",
        politica: false,
        step: 1,
        validateEmail: false,
    })
}));
