import Logo from "assets/icons/LogoArktoolsBlue.svg";
import { Button } from "components";
import { ChatContent } from "contexts/Chat.context";
import { useContext, useEffect, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router";
import MenuItem from "./MenuItem";
import * as S from "./style";

import { Sacola } from "assets/icons";
import { useStorePayment } from "contexts/Payment.context";
import { BsFillPersonFill } from "react-icons/bs";
import { FaBars } from "react-icons/fa";
import { DataDropdown } from "./DataDropdown";
import Dropdown from "./dropdown";
import { useStoreRegister } from "contexts/Register.context";

export default function Header() {
    const { setOpenModal, setStep} = useStoreRegister();
    const { setRecipient, socket } = useContext(ChatContent);
    const { setIsModalOpen, countItens } = useStorePayment();
    const router = useNavigate();
    const queryClient = useQueryClient();

    const token = localStorage.getItem("token");
    const [show, setShow] = useState<boolean>(false);
    const handleLogout = () => {
        setRecipient("");
        socket.disconnect();
        queryClient.resetQueries();
        localStorage.clear();
        setShow(false);
        setOpenModal(true);
    };

    const handleRoute = (route: string) => {
        router(`/${route}`);
    };
    const dropdownRef = useRef<HTMLDivElement>(null);
    const handleClickOutside = (event: MouseEvent) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target as Node)
        ) {
            setShow(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const [showMobileMenu, setShowMobileMenu] = useState(false);

    return (
        <S.MenuContainer showMobileMenu={showMobileMenu}>
            <S.MenuLogo>
                <S.Logo
                    src={Logo}
                    alt="logo"
                    onClick={() => handleRoute("home")}
                />
                <S.MenuMobileIcon
                    showMobileMenu={showMobileMenu}
                    onClick={() => setShowMobileMenu(!showMobileMenu)}
                >
                    <FaBars size={35} />
                </S.MenuMobileIcon>
            </S.MenuLogo>
            <S.MenuLinks showMobileMenu={showMobileMenu}>
                <MenuItem to="">Home</MenuItem>
                <MenuItem to="/servicesPage">Serviços</MenuItem>

                <MenuItem to="/projects">Projetos</MenuItem>
                {/* <MenuItem to="">Blog</MenuItem> */}
                <MenuItem to="/contact">Contato</MenuItem>
                <MenuItem to="/faq">FAQ</MenuItem>
                {token && (
                    <>
                        <MenuItem to="/orders">Meus Projetos</MenuItem>
                        {/* <MenuItem to="/3d-inserter">Document</MenuItem> */}
                        {/* {JsonGetRole == 1 &&
                            JsonGetUser.id == "650c5a5ab3a74319bb15328c" && (
                                <MenuItem to="/chat">Chat</MenuItem>
                            )}
                        {JsonGetRole == 0 && (
                            <MenuItem to="/chat">Chat</MenuItem>
                        )} */}
                    </>
                )}
            </S.MenuLinks>
            <S.ContentButton showMobileMenu={showMobileMenu}>
                {token ? (
                    <S.ContentHeaderUser ref={dropdownRef}>
                        <S.ContainerUser show={show}>
                            <S.ButtonUser onClick={() => setShow(!show)}>
                                <BsFillPersonFill size={25} color="#fff" />
                            </S.ButtonUser>
                            {show && (
                                <Dropdown
                                    dataDropdown={DataDropdown}
                                    handleFunction={handleLogout}
                                />
                            )}
                        </S.ContainerUser>
                        <S.Sacola onClick={() => setIsModalOpen(true)}>
                            <Sacola />
                            {countItens > 0 && (
                                <S.CountItens>{countItens}</S.CountItens>
                            )}
                        </S.Sacola>
                    </S.ContentHeaderUser>
                ) : (
                    <>
                        <S.ButtonLogin onClick={() => { setOpenModal(true); setStep(4); }}>
                            Login
                        </S.ButtonLogin>
                        <Button.Small onClick={() => setOpenModal(true)}>
                            Cadastre-se
                        </Button.Small>
                    </>
                )}
            </S.ContentButton>
        </S.MenuContainer>
    );
}
