import { useForm } from "react-hook-form";
import * as S from "../style";
import { DataProps } from "types";
import { LoginSchema } from "schemas";
import { zodResolver } from "@hookform/resolvers/zod";
import { useContext } from "react";
import { AuthContent } from "contexts/Auth.context";
import { useStoreRegister } from "contexts/Register.context";
import { Input } from "components/Input";
import Loading from "components/Loading";
import { ThemeContext } from "styled-components";
import { Button } from "components/Button";

export default function LoginStepFour() {
    const { PostAutentication, isloading } = useContext(AuthContent);
    const {handleCloseModal, setStep} = useStoreRegister();
    const theme = useContext(ThemeContext);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<DataProps<typeof LoginSchema>>({
        mode: "onBlur",
        resolver: zodResolver(LoginSchema),
    });
    const submitForm = async (data: any) => {
        const response = await PostAutentication(data);
        if (response?.code) return;
        handleCloseModal();
    };
    return(
        <S.FormInputContainerTwo onSubmit={handleSubmit(submitForm)}>

        <S.ContainerFour>
            <S.FourH3>
                Bem-vindo(a) de volta!
            </S.FourH3>
        <S.ContentInputRow>
            <Input.Text
                {...register("email")}
                type="text"
                name="email"
                label="E-mail"
                placeholder="E-mail"
                autoComplete="email"
                height={54}
                borderRadius={8}
                padding={20}
                helperText={errors?.email?.message}
                required
            />
             </S.ContentInputRow>
             <S.ContentInputLogin>
            <Input.Password
                {...register("password")}
                name="password"
                placeholder="Senha"
                autoComplete="current-password"
                height={54}
                label="Senha"
                required
                borderRadius={8}
                padding={20}
                helperText={errors?.password?.message}
            />
              <S.ForgotPassword onClick={()=> setStep(5)}>
                Esqueceu sua senha?
            </S.ForgotPassword>
            </S.ContentInputLogin>

            <S.ContentButton>
                        <Button.Master
                            width={300}
                            height={54}
                            size={1.125}
                            type="submit"
                            color={theme.mediumBlue}
                        >
                            {isloading ? <Loading size={20} /> : "Entrar"}
                        </Button.Master>
                    </S.ContentButton>
            </S.ContainerFour>
            </S.FormInputContainerTwo>
    );
}
