import { useStoreRegister } from "contexts/Register.context";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";

export default function PrivateRoute({ children }: any) {
    const storedIsAuthenticated = localStorage.getItem("isAuthenticated");
    const { setOpenModal } = useStoreRegister();

    useEffect(() => {
        if (!storedIsAuthenticated) {
            setOpenModal(true);
        }
    }, [storedIsAuthenticated, setOpenModal]);

    if (storedIsAuthenticated) {
        return children;
    }

    return <Navigate to="/" replace />;
}
