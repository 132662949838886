import styled from "styled-components";
import {  tabletMediaQuery } from "styles/themes/mediaQueries";

export const Close = styled.div`
    transition: transform 0.3s ease-in-out;
    position: absolute;
    top: -1em;
    right: -1em;
    svg {
        color: ${(props) => props.theme.primaryColorLight};
    }
    :hover {
        cursor: pointer;
        transform: scale(1.1);
    }
    ${tabletMediaQuery}{
        top: 0.5em;
        left: 0.5em;
    }
`;

export const ContainerModal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 4;
`;

export const ExternalModal = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    width: 99.4%;
    height: 101%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    @media (max-width: 700px) {
        justify-content: center;
    }
`;

export const Modal = styled.div`
    background: ${(props) => props.theme.white};
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 10px 10px -3px rgba(0, 0, 0, 0.1),
    0px 4px 5px -2px rgba(0, 0, 0, 0.04),
    0px 4px 4px -2px rgba(0, 0, 0, 0.02);
    position: relative;
    width: 1147px;
    margin-top: 1em;
    @media (min-width: 850px) and (max-width: 1440px){
        max-height: 700px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    ${tabletMediaQuery}{
        margin-top: 10px;
        max-width: 380px;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 700px;
    }
    @media (min-width: 1550px){
        margin-top: 4.9em;
        height: 770px;
    }
`;
export const Header = styled.header`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

export const Footer = styled.footer`
    border-top: 1px solid #d9d9d9;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    gap: 1em;
    width: 100%;
    padding: 1em 3em;
`;
