import { Email } from "assets/icons";
import * as S from "../style";
import { useEffect } from "react";
import { useStoreRegister } from "contexts/Register.context";
import { api } from "api/api";
export default function LoginStepThree(){
    const {validateEmail, setValidateEmail, handleCloseModal, email} = useStoreRegister();
    useEffect(() => {
        let intervalId: NodeJS.Timeout;
        const controller = new AbortController();
        const signal = controller.signal;

        const checkEmailValidation = async () => {
            try {
                const response = await api(`token/check-email-validation/${email}`, {
                    signal,
                    method: "GET"
                });
                const data = await response;

                if (data.used) {
                    setValidateEmail(true);
                    handleCloseModal();
                    clearInterval(intervalId);
                }
            } catch (error) {
                if (error instanceof Error && error.name !== "AbortError") {
                    console.error("Erro ao verificar a validação do e-mail:", error);
                }
            }
        };

        if (!validateEmail) {
            intervalId = setInterval(checkEmailValidation, 5000); // Verifica a cada 5 segundos
        }

        // Limpa o intervalo e aborta requisições pendentes quando o componente é desmontado
        return () => {
            clearInterval(intervalId);
            controller.abort();
        };
    }, [validateEmail]);
    return(
    <S.ContainerStepThree>
        <S.ContentStepThree>
            <S.ThreeH3>Valide sua conta via e-mail ou SMS</S.ThreeH3>
       <S.IconEmail>
        <Email />
        </S.IconEmail>
        <S.ThreeTitle>Enviamos um e-mail e um SMS para validar sua conta. Escolha o método que preferir. Isso pode levar alguns minutos.</S.ThreeTitle>
       <S.ThreeTitle>Não recebeu a mensagem? <strong>Clique aqui </strong>para reenviar a validação.</S.ThreeTitle>
       </S.ContentStepThree>
    </S.ContainerStepThree>
    );
}
