import { z } from "zod";

export const ForgotPasswordSchema = z.object({
 email: z
        .string()
        .email("E-mail inválido!")
        .min(1, { message: "Email é obrigatório!" }),
        phone: z.string().min(1, { message: "Telefone é obrigatório!" }),

    });
