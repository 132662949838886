"use client";
import { Sacola } from "assets/icons";
import * as S from "./style";
import { Loading } from "components";
import { useStorePayment } from "contexts/Payment.context";
import { useGetCarrinho } from "hooks";
import { useEffect } from "react";
import { BsDash, BsPlus, BsXLg } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

import { useDeleteItemCarrinho, usePutCarrinho } from "hooks/useCarrinho";
import { CalculoValorTotal } from "utils/calculoValorTotal";
export default function Modal() {
    const router = useNavigate();
    const storedIsAuthenticated = localStorage.getItem("isAuthenticated");
    const {
        isModalOpen,
        setIsModalOpen,
        setModalContent,
        countItens,
        setCountItens,
        total,
        setTotal,
        setAmount,
    } = useStorePayment();

    const formatValue = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
    });

    const { refetchListCarrinho, listCarrinho, isLoadingListCarrinho } =
        useGetCarrinho();

    const { putCarrinho } = usePutCarrinho();
    const { deleteItemCarrinho } = useDeleteItemCarrinho();

    function Close() {
        setIsModalOpen(false);
        setModalContent(null);
    }

    useEffect(() => {
        async function ListDataCarrinho() {
            if (storedIsAuthenticated) {
                await refetchListCarrinho();
            }
            setCountItens(listCarrinho?.length);
        }

        ListDataCarrinho();
        if (listCarrinho) {
            let sum = 0;
            listCarrinho?.forEach((item: any) => {
                if (
                    item.service[0]?.valueNoSignature !== null &&
                    item.service[0]?.valueNoSignature !== undefined
                ) {
                    const valorTotal = CalculoValorTotal({
                        listExtraStyles: item?.upload[0]?.extraStyles,
                        listService: item.service[0],
                    });

                    sum += item.count * valorTotal;
                }
            });
            setTotal(sum);
            setAmount(sum);
        }
    }, [listCarrinho]);

    const handleFinalizeClick = () => {
        Close();
        router("/payment");
    };

    const handleDeleteItemCarrinho = async (id: string) => {
        await deleteItemCarrinho(id);
        refetchListCarrinho();
    };

    async function handleCountMinus(number: number, id: string) {
        if (number > 1) {
            const newCount = number - 1;
            const carrinho = {
                id,
                count: newCount,
            };
            await putCarrinho(carrinho);
            refetchListCarrinho();
        }
    }
    async function handleCountAdd(number: number, id: string) {
        const newCount = number + 1;
        const carrinho = {
            id,
            count: newCount,
        };
        await putCarrinho(carrinho);
        refetchListCarrinho();
    }
    return (
        isModalOpen && (
            <S.ContainerModal>
                <S.ExternalModal>
                    <S.Modal>
                        <S.Header>
                            <S.Close onClick={Close}>
                                <BsXLg size={23} />
                            </S.Close>
                            <h3>MINHAS COMPRAS</h3>
                            <S.Sacola>
                                <Sacola />
                                {countItens > 0 && (
                                    <S.CountItens>{countItens}</S.CountItens>
                                )}
                            </S.Sacola>
                        </S.Header>
                        <S.ContentSacola>
                            {isLoadingListCarrinho ? (
                                <Loading size={20} color="#487B9D" />
                            ) : (
                                listCarrinho?.map(
                                    (item: any, index: number) => {
                                        const valorTotal = CalculoValorTotal({
                                            listExtraStyles:
                                                item?.upload[0]?.extraStyles,
                                            listService: item.service[0],
                                        });
                                        return (
                                            <S.ItemSacola key={index}>
                                                <div>
                                                    <img
                                                        src={
                                                            item?.service[0]
                                                                ?.imageURL
                                                        }
                                                        alt={
                                                            item?.service[0]
                                                                ?.title
                                                        }
                                                    />
                                                </div>
                                                <S.DescriptionSacola>
                                                    <h4>
                                                        {
                                                            item?.service[0]
                                                                ?.title
                                                        }
                                                    </h4>
                                                    <span>
                                                        {item?.service[0]
                                                            ?.description <= 86
                                                            ? item?.service[0]
                                                                  ?.description
                                                            : `${item?.service[0]?.description.slice(
                                                                  0,
                                                                  86
                                                              )}...`}
                                                    </span>
                                                    <S.ContentProduct>
                                                        <S.ContentQuantity>
                                                            <S.Minus
                                                                onClick={() =>
                                                                    handleCountMinus(
                                                                        item?.count,
                                                                        item.id
                                                                    )
                                                                }
                                                            >
                                                                <BsDash />
                                                            </S.Minus>
                                                            <S.ProductQuantity>
                                                                <span>
                                                                    {
                                                                        item?.count
                                                                    }
                                                                </span>
                                                            </S.ProductQuantity>
                                                            <S.Minus
                                                                onClick={() =>
                                                                    handleCountAdd(
                                                                        item?.count,
                                                                        item.id
                                                                    )
                                                                }
                                                            >
                                                                <BsPlus />
                                                            </S.Minus>
                                                        </S.ContentQuantity>
                                                        <S.RemoveBtn
                                                            onClick={() => {
                                                                handleDeleteItemCarrinho(
                                                                    item.id
                                                                );
                                                            }}
                                                        >
                                                            Remover
                                                        </S.RemoveBtn>
                                                        <S.ValueProduct>
                                                            {formatValue.format(
                                                                item?.count *
                                                                    valorTotal
                                                            )}
                                                        </S.ValueProduct>
                                                    </S.ContentProduct>
                                                </S.DescriptionSacola>
                                            </S.ItemSacola>
                                        );
                                    }
                                )
                            )}
                        </S.ContentSacola>
                        <S.Footer>
                            <S.Value>
                                <span>Subtotal</span>
                                <span>{formatValue.format(total)}</span>
                            </S.Value>
                            <S.Value>
                                <span>Descontos</span>
                                <span>R$ 0</span>
                            </S.Value>
                            <S.Value>
                                <span>Total</span>
                                <S.Total>{formatValue.format(total)}</S.Total>
                            </S.Value>
                            <S.ButtonConfirmar
                                type="button"
                                onClick={handleFinalizeClick}
                            >
                                Finalizar compra
                            </S.ButtonConfirmar>
                        </S.Footer>
                    </S.Modal>
                </S.ExternalModal>
            </S.ContainerModal>
        )
    );
}
