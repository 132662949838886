import { HelperText } from "components";
import React, { useId, useState } from "react";
import { InputType } from "types";
import * as S from "./style";
import { BsEyeFill, BsEyeSlash } from "react-icons/bs";

const InputPassword = React.forwardRef<HTMLInputElement, InputType>(
    (
        {
            height,
            borderRadius,
            padding,
            name = "",
            label = "",
            helperText = "",
            required = false,
            ...props
        },
        ref
    ) => {
        const inputId = useId();
        const hasError = helperText.length > 0;
        const [showPassword, setShowPassword] = useState<boolean>(false);
        return (
            <S.Container>
                <S.Label padding={padding} htmlFor={inputId}>
                    {label}
                    {required && <strong>*</strong>}
                </S.Label>
                <S.ContainerPassword>
                <S.Input
                    ref={ref}
                    type={showPassword ? "text" : "password"}
                    id={inputId}
                    name={name}
                    height={height}
                    padding={padding}
                    borderRadius={borderRadius}
                    hasError={hasError}
                    {...props}
                />
                <S.Icon onClick={() => setShowPassword(!showPassword)}>
                    {
                        showPassword ? <BsEyeSlash size={22}/> : <BsEyeFill  size={22}/>
                    }

                    </S.Icon>
                    </S.ContainerPassword>

                {hasError && <HelperText helperText={helperText} />}
            </S.Container>
        );
    }
);

export default InputPassword;
