export default () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="39"
        height="39"
        viewBox="0 0 39 39"
        fill="none"
    >
        <circle
            cx="19.5"
            cy="19.5"
            r="19.0568"
            fill="white"
            stroke="#487B9D"
            stroke-width="0.886364"
        />
        <path
            d="M13 13L26.4775 26.4775L13 13ZM13 26.4775L26.4775 13L13 26.4775Z"
            fill="white"
        />
        <path
            d="M13 13L26.4775 26.4775M13 26.4775L26.4775 13"
            stroke="#487B9D"
            stroke-width="2.02163"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);
