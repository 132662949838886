import { useStoreRegister } from "contexts/Register.context";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { DataProps } from "types";
import { ThemeContext } from "styled-components";
import { ForgotPasswordSchema } from "schemas/ForgotPasswordSchema.schema";
import { zodResolver } from "@hookform/resolvers/zod";
import * as S from "../style";
import { Input } from "components/Input";
import { Button } from "components/Button";
import Loading from "components/Loading";
import { api } from "api/api";

export default function LoginForgotPassword() {
    const theme = useContext(ThemeContext);
    const [isloading, setIsLoading] = useState<boolean>(false);
    const { setStep, setEmail} = useStoreRegister();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<DataProps<typeof ForgotPasswordSchema>>({
        mode: "onBlur",
        resolver: zodResolver(ForgotPasswordSchema),
    });

    const submitForm = async (data: any) => {
        try{


        setEmail(data.email);
     const response = await api("code/forgot-password", {
        data: JSON.stringify(data),
        method: "POST"
    });
    if(response.message === "Código enviado com sucesso!"){
     setStep(6);
    }
}catch(error){
    console.log(error);
}finally{
    setIsLoading(false);
}
};
    return(
        <S.FormInputContainerTwo onSubmit={handleSubmit(submitForm)}>

        <S.ContainerTwo>
            <S.FourH3>
            Esqueceu sua senha?
            </S.FourH3>
            <S.DescriptionForgotPassword>
            Insira seu e-mail ou telefone celular,  enviaremos um código de validação para resetar sua senha.
            </S.DescriptionForgotPassword>
        <S.ContentInputRow>
            <Input.Text
                {...register("email")}
                type="text"
                name="email"
                label="E-mail"
                placeholder="Insira seu e-mail"
                autoComplete="email"
                height={54}
                borderRadius={8}
                padding={20}
                helperText={errors?.email?.message}
                required
            />
             </S.ContentInputRow>
             <S.ContentInputRow>
             <Input.Text
                {...register("phone")}
                type="text"
                name="phone"
                label="Telefone celular"
                placeholder="Insira seu número"
                height={54}
                borderRadius={8}
                padding={20}
                helperText={errors?.phone?.message}
                required
            />
            </S.ContentInputRow>

            <S.ContentButton>
                        <Button.Master
                            width={300}
                            height={54}
                            size={1.125}
                            type="submit"
                            color={theme.mediumBlue}
                        >
                            {isloading ? <Loading size={20} /> : "Enviar"}
                        </Button.Master>
                    </S.ContentButton>
            </S.ContainerTwo>
            </S.FormInputContainerTwo>
    );
}
