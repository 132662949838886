export default () => (
    <svg
        width="90"
        height="90"
        viewBox="0 0 90 90"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g filter="url(#filter0_d_1587_2407)">
            <path
                d="M20.067 42.7823C20.0658 46.9972 21.1721 51.1127 23.2756 54.7402L19.8658 67.1343L32.6065 63.8086C36.1303 65.7184 40.0786 66.7191 44.0908 66.7194H44.1014C57.3465 66.7194 68.1283 55.9895 68.134 42.8012C68.1365 36.4104 65.6389 30.401 61.101 25.8799C56.5639 21.3591 50.5297 18.8682 44.1004 18.8652C30.8537 18.8652 20.0727 29.5945 20.0672 42.7823"
                fill="url(#paint0_linear_1587_2407)"
            />
            <path
                d="M19.209 42.7749C19.2076 47.1415 20.3535 51.4042 22.532 55.1616L19 68L32.1975 64.5551C35.8338 66.5289 39.9279 67.5695 44.0939 67.5711H44.1047C57.825 67.5711 68.9941 56.4552 69 42.7948C69.0023 36.1744 66.4148 29.949 61.7148 25.2659C57.0143 20.5833 50.7643 18.0027 44.1047 18C30.382 18 19.2145 29.1143 19.209 42.7749ZM27.0686 54.5145L26.5758 53.7358C24.5043 50.4567 23.4109 46.6675 23.4125 42.7765C23.4168 31.4221 32.6988 22.1844 44.1125 22.1844C49.6398 22.1867 54.8344 24.3318 58.7414 28.2237C62.6482 32.116 64.798 37.2901 64.7967 42.7932C64.7916 54.1476 55.5094 63.3865 44.1047 63.3865H44.0965C40.383 63.3845 36.741 62.3917 33.5648 60.5156L32.809 60.0693L24.9773 62.1135L27.0686 54.5145Z"
                fill="url(#paint1_linear_1587_2407)"
            />
            <path
                d="M37.8824 32.4174C37.4164 31.3863 36.926 31.3655 36.4828 31.3474C36.1199 31.3319 35.7051 31.333 35.2906 31.333C34.8758 31.333 34.2018 31.4884 33.632 32.1077C33.0617 32.7276 31.4547 34.2255 31.4547 37.2722C31.4547 40.3191 33.6838 43.2636 33.9945 43.6771C34.3057 44.0899 38.2979 50.5423 44.6205 53.0245C49.8752 55.0873 50.9445 54.6771 52.085 54.5736C53.2256 54.4706 55.7654 53.076 56.2836 51.63C56.8022 50.1841 56.8022 48.9447 56.6467 48.6858C56.4912 48.4277 56.0764 48.2728 55.4543 47.9632C54.832 47.6535 51.7738 46.1553 51.2037 45.9486C50.6334 45.7421 50.2188 45.639 49.8039 46.2591C49.3891 46.8782 48.1979 48.2728 47.8348 48.6858C47.4721 49.0997 47.109 49.1512 46.4871 48.8415C45.8647 48.5308 43.8613 47.8777 41.4846 45.7682C39.6354 44.1267 38.3869 42.0996 38.024 41.4796C37.6611 40.8605 37.9852 40.5249 38.2971 40.2163C38.5766 39.9388 38.9194 39.4931 39.2307 39.1317C39.5408 38.77 39.6444 38.512 39.8518 38.099C40.0594 37.6856 39.9555 37.324 39.8002 37.0142C39.6444 36.7045 38.4356 33.6418 37.8824 32.4174Z"
                fill="white"
            />
        </g>
        <defs>
            <filter
                id="filter0_d_1587_2407"
                x="0"
                y="0"
                width="90"
                height="90"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dx="1" dy="2" />
                <feGaussianBlur stdDeviation="10" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.435294 0 0 0 0 0.666667 0 0 0 0 0.764706 0 0 0 0.53 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1587_2407"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_1587_2407"
                    result="shape"
                />
            </filter>
            <linearGradient
                id="paint0_linear_1587_2407"
                x1="2433.27"
                y1="4845.77"
                x2="2433.27"
                y2="18.8652"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#1FAF38" />
                <stop offset="1" stopColor="#60D669" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_1587_2407"
                x1="2519"
                y1="5018"
                x2="2519"
                y2="18"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F9F9F9" />
                <stop offset="1" stopColor="white" />
            </linearGradient>
        </defs>
    </svg>
);
