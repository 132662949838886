import { api } from "api/api";
import axios from "axios";
import { useState } from "react";

export function useConfirmEmail() {
    const [resp, setResp] = useState<any>();
    async function postToken(token: string) {
        try {
            const response = await api(`token/${token}`, {
                method: "POST",
            });

          setResp(response.message);
            return response;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const axiosError = error as any;
                setResp(axiosError?.response?.data?.message);
                return error;
            }
        }
    }
    return { postToken, resp };
}
