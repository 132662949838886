type TypeDropdownData = {
    title: string;
    url: string;
};

export const DataDropdown: Array<TypeDropdownData> = [
    {
        title: "Contratar serviço",
        url: "/service-contracting",
    },
    {
        title: "Meus projetos",
        url: "/orders",
    },
];
