import styled from "styled-components";
import { desktopMediaQuery, tabletMediaQuery } from "styles/themes/mediaQueries";
export const Banner = styled.div`
    display: flex;
    align-items: center;
    img {
        width: 467.461px;
        height: 262.9px;
    }
`;
export const ContainerLogin = styled.div`
${desktopMediaQuery}{


    display: grid;
   grid-template-columns: 0.5fr 1.5fr;
    height: 100%;
}
    ${tabletMediaQuery}{
    display: flex;

    }
`;
export const ContentBanner = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    ${tabletMediaQuery}{
        display: none;
    }
`;
export const Content = styled.div`
    padding: 24px 33px 24px 34px;
    border-radius: 0px 20px 20px 0px;
    border: 0.8px solid #deeaed;
    box-shadow: 2px 2px 10px 0px rgba(104, 118, 121, 0.15);
`;

export const ContentImage = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const LoginTextContainer = styled.div`
    display: flex;
    margin-top: -6em;
    justify-content: end;
    padding-right: 24px;

    span {
        font-size: 1.125rem;
        color: #4d4d4d;
    }
    strong {
        font-size: 1.125rem;
        color: #487b9d;
        font-weight: 500;
        cursor: pointer;
    }
`;
export const ContentButton = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 9px;
`;

export const Title = styled.h1`
    color: ${(props) => props.theme.black};
    font-weight: 700;
    font-size: 1.5rem;
    margin-top: 13px;
    margin-bottom: 58px;
`;
export const ContentStep = styled.div`
    width: 100%;
    text-align: end;
`;
export const TitleDados = styled.div`
    color: #4d4d4d;
    font-weight: 700;
    font-size: 1rem;
    margin-bottom: 36px;
`;
export const ContentInputRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: 40px;
    ${tabletMediaQuery}{
        flex-wrap: wrap;
    }
`;

export const ContentInputLogin = styled.div`
display: flex;
flex-direction: column;
`;
export const FormInputContainer = styled.form`
    display: flex;
    flex-direction: column;
    gap: 29px;

`;
export const Description = styled.div`
    color: #4d4d4d;
    font-size: 1rem;
    margin-bottom: 24px;
`;

export const Step = styled.strong`
    font-size: 1.125rem;
    color: #a8a8a8;
    font-weight: 500;
    margin-right: -9px;
`;


export const RegisterPolitica = styled.div`
    display: flex;
    gap: 1em;
    flex-direction: column;
    justify-content: center;
`;

export const TitlePolitica = styled.span`
    font-size: 0.7rem;
`;

export const Politica = styled.div`
    display: flex;
    gap: 0.5em;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    input {
        margin-top: 0.2em;
    }
    strong {
        color: #1f67b1;
        font-weight: 500;
        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }
`;

export const ContainerStepThree = styled.div`
width: 100%;
display: flex;
align-items: center;
justify-content: center;
`;
export const ContentStepThree = styled.div`
max-width: 500px;
display: flex;
flex-direction: column;
margin-top: 4em;
`;

export const FormInputContainerTwo = styled(FormInputContainer)`
width: 100%;
display: flex;
align-items: center;

`;
export const ContainerTwo  = styled.div`
max-width: 300px;
gap: 29px;
display: flex;
flex-direction: column;
margin-top: 4em;
`;

export const ContainerFour = styled(ContainerTwo)`
margin-top: 1em;
`;

export const ThreeH3 = styled.h3`
font-size: 1rem;
font-weight: 700;
color: #4D4D4D;
text-align: center;
`;

export const ThreeTitle = styled.span`
font-weight: 400;
color: #4D4D4D;
font-size: 1rem;
margin-bottom: 1em;
strong{
font-size: 1rem;
    color: #1f67b1;
font-weight: 600;
cursor: pointer;

}
`;

export const IconEmail = styled.div`
display: flex;
align-items: center;
width: 100%;
justify-content: center;
margin: 24px 0;
`;


export const FourH3 = styled.h3`
font-size: 1.5rem;
color: #4D4D4D;
font-weight: 700;
`;

export const ForgotPassword = styled.span`
font-size: 0.8rem;
color:#1f67b199;
font-weight: 600;
cursor: pointer;
`;

export const DescriptionForgotPassword = styled.span`
color: #4D4D4D;
font-size: 1rem;
font-weight: 600;
`;
