import { useStoreRegister } from "contexts/Register.context";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { DataProps } from "types";
import { ThemeContext } from "styled-components";
import { zodResolver } from "@hookform/resolvers/zod";
import * as S from "../style";
import { Input } from "components/Input";
import { Button } from "components/Button";
import Loading from "components/Loading";
import { ForgotPasswordValidationSchema } from "schemas/ForgotPasswordValidationSchema";
import { api } from "api/api";
import { toast } from "react-toastify";

export default function LoginForgotPasswordValidation() {
    const theme = useContext(ThemeContext);
    const [isloading, setIsLoading] = useState<boolean>(false);
    const { setStep} = useStoreRegister();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<DataProps<typeof ForgotPasswordValidationSchema>>({
        mode: "onBlur",
        resolver: zodResolver(ForgotPasswordValidationSchema),
    });

    const submitForm = async (data: any) => {

        try{
            setIsLoading(true);
            const response = await api(`code/${data.codigo}`, {
                method: "get"
            });

            if(response.message === "Código válido!"){
                setStep(7);
            }else{
                toast.error(response.message);
            }
        }catch(error){
            console.log(error);
        }finally{
            setIsLoading(false);
        }
    };
    return(
        <S.FormInputContainerTwo onSubmit={handleSubmit(submitForm)}>

        <S.ContainerTwo>
            <S.FourH3>
            Código de validação
            </S.FourH3>
            <S.DescriptionForgotPassword>
            Enviamos um e-mail e um SMS com o código de validação, por favor, informe o código abaixo para resetar sua senha:
            </S.DescriptionForgotPassword>
        <S.ContentInputLogin>
            <Input.Text
                {...register("codigo")}
                type="text"
                name="codigo"
                label="Código"
                placeholder="Insira aqui o código"
                height={54}
                borderRadius={8}
                padding={20}
                helperText={errors?.codigo?.message}
                required
                maxLength={4}
            />
            <S.ForgotPassword onClick={()=> setStep(5)}>
                Esqueceu sua senha?
            </S.ForgotPassword>
             </S.ContentInputLogin>


            <S.ContentButton>
                        <Button.Master
                            width={300}
                            height={54}
                            size={1.125}
                            type="submit"
                            color={theme.mediumBlue}
                        >
                            {isloading ? <Loading size={20} /> : "Confirmar"}
                        </Button.Master>
                    </S.ContentButton>
            </S.ContainerTwo>
            </S.FormInputContainerTwo>
    );
}
