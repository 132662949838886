import styled from "styled-components";
import { mobileMediaQuery, tabletMediaQuery } from "styles/themes/mediaQueries";

export const Button = styled.button<{
    color?: string;
    width?: number;
    height?: number;
    size?: number;
}>`
    border-radius: 8px;
    background-color: ${({ color, theme }) =>
        color ? color : theme.primaryColor};
    padding: 12px;
    border: solid 1px ${(props) => props.theme.primaryColor};
    color: ${(props) => props.theme.white};
    width: ${({ width }) => (width ? `${width}px` : "100%")};
    height: ${({ height }) => (height ? `${height}px` : "auto")};
    font-family: "Quicksand Variable", sans-serif;
    font-size: ${({ size }) => (size ? size : 1)}rem;
    &:hover {
        cursor: pointer;
        background-color: ${(props) => props.theme.darkBlue};
    }
`;

export const SmallButton = styled(Button)`
    width: auto;
    background-color: ${(props) => props.theme.mediumBlue};
    padding: 8px 10px;
    color: ${(props) => props.theme.white};
    font-weight: 400;
    font-size: 1.1rem;
    border: none;
    border-radius: 8px;
    font-family: "Quicksand Variable", sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    ${tabletMediaQuery} {
        font-size: 1rem;
    }

    ${mobileMediaQuery} {
        font-size: 0.7rem;
    }
`;
