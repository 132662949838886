import { useStoreRegister } from "contexts/Register.context";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { DataProps } from "types";
import { ThemeContext } from "styled-components";
import { zodResolver } from "@hookform/resolvers/zod";
import * as S from "../style";
import { Input } from "components/Input";
import { Button } from "components/Button";
import Loading from "components/Loading";
import { ForgotPasswordChangeSchema } from "schemas/ForgotPasswordChangeSchema";
import { api } from "api/api";
import { toast } from "react-toastify";

export default function LoginForgotPasswordChange() {
    const theme = useContext(ThemeContext);
    const [isloading, setIsLoading] = useState<boolean>(false);
    const { setStep, email} = useStoreRegister();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<DataProps<typeof ForgotPasswordChangeSchema>>({
        mode: "onBlur",
        resolver: zodResolver(ForgotPasswordChangeSchema),
    });

    const submitForm = async (data: any) => {
        try{
            setIsLoading(true);
            const updateData = {
                email,
                password: data.password
            };
            const response = await api("user/forgot-password", {
                data: JSON.stringify(updateData),
                method: "put"
            });
            if(response.message === "Senha alterada com sucesso!"){
                setStep(8);
            }else{
                toast.error(response.message);
            }
        }catch(error){
            console.log(error);
        }finally{
            setIsLoading(false);
        }
    };
    return(
        <S.FormInputContainerTwo onSubmit={handleSubmit(submitForm)}>

        <S.ContainerTwo>
            <S.FourH3>
            Altere sua senha
            </S.FourH3>
            <S.DescriptionForgotPassword>
            A senha deve ter no mínimo 8 caracteres, sendo 1 letra maiúscula, 1 letra minúscula, 1 caractere especial e 1 número.
            </S.DescriptionForgotPassword>
        <S.ContentInputRow>
        <Input.Password
                {...register("password")}
                name="password"
                placeholder="Senha"
                autoComplete="current-password"
                height={54}
                label="Senha"
                required
                borderRadius={8}
                padding={20}
                helperText={errors?.password?.message}
            />

             </S.ContentInputRow>
             <S.ContentInputRow>
             <Input.Password
                {...register("repeatPassword")}
                name="repeatPassword"
                placeholder="Insira sua nova senha"
                height={54}
                label="Confirme nova senha"
                required
                borderRadius={8}
                padding={20}
                helperText={errors?.repeatPassword?.message}
            />

             </S.ContentInputRow>


            <S.ContentButton>
                        <Button.Master
                            width={300}
                            height={54}
                            size={1.125}
                            type="submit"
                            color={theme.mediumBlue}
                        >
                            {isloading ? <Loading size={20} /> : "Confirmar"}
                        </Button.Master>
                    </S.ContentButton>
            </S.ContainerTwo>
            </S.FormInputContainerTwo>
    );
}
