import { CloseModal } from "assets/icons";
import { ReactElement } from "react";
import * as S from "./style";
import * as StyledLogin from "../Login/style";

import BannerIlustracao from "assets/images/ArktoolsBannerIlust.png";
import { useStoreRegister } from "contexts/Register.context";
import LoginFirstStep from "components/Login/first-step";
import LoginStepTwo from "components/Login/two-step";
import LoginStepThree from "components/Login/three-step";
import LoginStepFour from "components/Login/four-step";
import LoginForgotPassword from "components/Login/LoginForgotPassword";
import LoginForgotPasswordValidation from "components/Login/LoginForgotPasswordValidation";
import LoginForgotPasswordChange from "components/Login/LoginForgotPasswordChange";
import LoginForgotPasswordSuccess from "components/Login/LoginForgotPasswordSuccess";


export default function ModalContent() {
    const {step, handleCloseModal, openModal, setStep} = useStoreRegister();
    function Close() {
        handleCloseModal();
    }
    if (!openModal) {
        return null;
    }

    const steps: { [key: number]: ReactElement } = {
        1: <LoginFirstStep />,
        2: <LoginStepTwo />,
        3: <LoginStepThree />,
        4: <LoginStepFour />,
        5: <LoginForgotPassword />,
        6: <LoginForgotPasswordValidation />,
        7: <LoginForgotPasswordChange />,
        8: <LoginForgotPasswordSuccess />
    };

    const TitleSteps: { [key: number]: ReactElement } = {
        1: (
            <> Para contratar nossos serviços, é necessário criar seu
                cadastro e aceitar nossos termos e condições, além da
                nossa política de privacidade.</>
        ),
        2: <>Estamos finalizando seu cadastro, por favor, preencha os dados a seguir:</>,
        3: <>Por favor, valide sua conta para iniciarmos:</>,
        4: <></>
    };

    return (
        <S.ContainerModal>
            <S.ExternalModal>
                <S.Modal>
                    <S.Header>
                        <S.Close onClick={Close}>
                            <CloseModal />
                        </S.Close>
                    </S.Header>
                    <StyledLogin.ContainerLogin>
            <StyledLogin.Banner>
                <StyledLogin.ContentBanner>
                    <StyledLogin.ContentImage>
                        <img
                            src={BannerIlustracao}
                            alt="Banner de ilustração"
                        />
                    </StyledLogin.ContentImage>
                    <StyledLogin.LoginTextContainer>
                        <span>

                        {step === 4 ? (
                            <>Não tem cadastro? <strong onClick={()=> setStep(1)}>Cadastre-se.</strong></>
                        ) : (
                            <>Já é cadastrado? <strong onClick={()=> setStep(4)}>Faça login.</strong></>
                        )}
                        </span>
                    </StyledLogin.LoginTextContainer>
                </StyledLogin.ContentBanner>
            </StyledLogin.Banner>
            <StyledLogin.Content>
                        <StyledLogin.ContentStep>
                            <StyledLogin.Step>
{
(step >= 1 && step <= 3) && (
    <>Passo {step} de 3</>
)
}
                                </StyledLogin.Step>
                        </StyledLogin.ContentStep>

                                <StyledLogin.Title>
                                {
                            step === 1 && (
                                <>Seja bem-vindo(a) ao Arktools!</>
                                )
                            }
                                    </StyledLogin.Title>

                        <StyledLogin.Description>
                            <span>
                                {TitleSteps[step]}
                            </span>
                        </StyledLogin.Description>
                        {
                            step === 1 && (
                            <StyledLogin.TitleDados>
                            <span>Por favor, preencha os dados a seguir:</span>
                        </StyledLogin.TitleDados>
                            )
                        }

                         {steps[step]}
                    </StyledLogin.Content>
                    </StyledLogin.ContainerLogin>
                </S.Modal>
            </S.ExternalModal>
        </S.ContainerModal>
    );
}
